<template>
  <div class="relative" :class="colSpan">
    <label class="text-xs" :for="field.handle">
      {{ label || field.label }}
    </label>

    <Field
      :id="field.handle"
      v-model="innerValue"
      class="focus-default block h-[3.75rem] w-full rounded-md bg-second-blue-200/50 px-5 text-base"
      :class="{
        'border-main-red': error,
      }"
      :name="field.handle"
      :type="field.config.input_type"
      :placeholder="placeholder || field.config?.placeholder || label"
      :disabled="disabled"
      :rules="rulesAsString"
    />
    <transition name="fade">
      <p v-if="error" class="mt-1 text-xs text-red-500">{{ error }}</p>
    </transition>
  </div>
</template>

<script>
import { Field } from 'vee-validate'

export default {
  components: {
    Field,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    value: {
      type: null,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    rules: {
      type: [Array, String],
      default: '',
    },

    error: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      innerValue: this.value,
    }
  },

  computed: {
    rulesAsString() {
      if (Array.isArray(this.rules)) {
        return this.rules.join('|')
      }
      return this.rules
    },

    colSpan() {
      const width = this.field.width || 100

      if (this.field.handle === 'zip') {
        return 'sm:col-span-2 col-span-1 mr-[-0.125rem] sm:mr-[-0.4rem] xl:mr-[-0.625rem]'
      }
      if (this.field.handle === 'city') {
        return 'sm:col-span-4 col-span-3 ml-[-0.125rem] sm:ml-[-0.4rem] xl:ml-[-0.625rem]'
      }

      switch (width) {
        case 25:
          return 'sm:col-span-3 col-span-2'
        case 33:
          return 'sm:col-span-4 col-span-4'
        case 50:
          return 'sm:col-span-6 col-span-4'
        case 66:
          return 'sm:col-span-8 col-span-4'
        default:
          return 'sm:col-span-12 col-span-4'
      }
    },
  },

  watch: {
    innerValue(innerValue) {
      this.$emit('field-input', innerValue)
    },

    value(value) {
      this.innerValue = value
    },
  },
}
</script>
